import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10400;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
`;

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const Close = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: linear-gradient(
    341.1deg,
    rgba(110, 226, 245, 0.3) -28.66%,
    rgba(100, 84, 240, 0.3) 87.23%
  );

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 20px 40px;
  .img {
    margin-bottom: 30px;
  }
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  margin: 0;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 320px) {
    margin-right: 10px;
  }

  &.header {
    justify-content: space-between;
    border-bottom: solid 1px #7f7f7f;
    padding: 16px;
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: radial-gradient(
    50.55% 79.92% at 70.55% 52.33%,
    #000041 0%,
    #000030 49.5%,
    #00002a 100%
  );
  z-index: 100;
  min-width: 460px;
  max-width: 460px;
  vertical-align: middle;
  p {
    text-align: center;
  }
`;

export const ModalContent = styled.div`
  color: #8d8d8d;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  line-height: 1.69;
  display: flex;
  flex-direction: column;

  .description {
    font-weight: 400;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 20px;

    &.highlight {
      font-size: 30px !important;
      font-weight: 700 !important;
      line-height: 34px !important;
      background: linear-gradient(113.96deg, #ffcda5 0%, #ee4d5f 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .sub_description {
    font-weight: 400;
    line-height: 20px;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 0;

    &.highlight {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      color: rgba(0, 255, 237, 1);
      text-shadow: 0px 0px 40px 0px rgba(0, 178, 255, 1);
    }
  }

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FooterModal = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  button {
    min-width: 152px;
    height: 44px;
    margin: 20px 0 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;
