import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import Navigate from "@components/Navigate";
import { login, verify } from "@network/Auth/apiAuth";
import { useDispatch } from "react-redux";
import { updateUser } from "@store/authReducer";
import { updateNotice } from "@store/noticeReducer";
import OtpInput from "react-otp-input";
import { useNavigate, useParams } from "react-router-dom";

import Img from "@assets/img";
import { Errors } from "@const/error";
import { RequestLogin } from "@network/Auth/type";
import { routes } from "@const/routes";
import { Helmet } from "react-helmet";

const Login: React.FC = () => {
  const { id } = useParams();
  const [phone, setPhone] = useState<string | undefined>();
  const [otp, setOtp] = useState<string>();
  const [isCheck, setIsCheck] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isInValidPhone, setIsInValidPhone] = useState<boolean>(false);
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [isResend, setIsResend] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer - 1 === 0) {
        setIsResend(true);
        clearInterval(interval);
      }
      setTimer((prevTimer) => (prevTimer - 1 >= 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const onSend = async () => {
    try {
      if (isError || !phone) return;

      const res = await login({ phone });

      if (res.status === "error") {
        return dispatch(
          updateNotice(
            {
              type: "ERROR",
              content: Errors[res.message]
                ? Errors[res.message]
                : Errors.SEND_OTP_ERROR,
            },
            dispatch
          )
        );
      }

      setShowOtp(true);

      setTimer(59);
    } catch (error) {
      console.log(error);
    }
  };

  const onVerify = async () => {
    try {
      if (!otp || !isCheck || !phone) {
        setIsError(true);

        return;
      }

      setIsError(false);
      const verifyPayload: RequestLogin = {
        phone,
        otp,
      };
      if (id) verifyPayload.invitedBy = Number(id);

      const res = await verify(verifyPayload);

      if (res.status === "error") {
        return dispatch(
          updateNotice(
            {
              type: "ERROR",
              content: Errors[res.message]
                ? Errors[res.message]
                : Errors.VERIFY_OTP_ERROR,
            },
            dispatch
          )
        );
      }

      dispatch(updateUser(res.data));
      navigate(routes.dashboard);
    } catch (error) {
      console.log(error);
    }
  };

  const showTime = (timer: number) =>
    timer > 9 ? `00:${timer}` : `00:0${timer}`;

  useEffect(() => {
    if (!phone || !/^\d{1,10}$/.test(phone)) {
      setIsError(true);
      if (phone === undefined && !isCheck) {
        setIsInValidPhone(false);
      } else {
        setIsInValidPhone(true);
      }

      return;
    }
    setIsInValidPhone(false);

    if (!isCheck) {
      return setIsError(true);
    }
    setIsError(false);
  }, [isCheck, phone]);

  return (
    <>
      <Helmet>
        <title>TWO-Login</title>
      </Helmet>
      <Navigate />
      <div
        className="page-header page-login"
        style={{
          backgroundImage: `url(${Img.LoginImage})`,
        }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="5">
              <Card className="card-register ml-auto mr-auto">
                <h3 className="title mx-auto">Login</h3>
                <p className="text-center sub-title">
                  Register and login with your mobile OTP
                </p>
                <Form className="register-form">
                  <div
                    className={
                      isError && isCheck && !showOtp
                        ? "has-danger form-group"
                        : "form-group"
                    }
                  >
                    <label>Phone number</label>
                    <Input
                      disabled={showOtp}
                      placeholder="Input your phone number"
                      value={phone}
                      maxLength={15}
                      onChange={(e) => setPhone(e.target.value)}
                      type="text"
                    />
                    <div
                      className="form-control-feedback"
                      hidden={!isInValidPhone}
                    >
                      Invalid phone number format
                    </div>
                  </div>
                  <Button
                    block
                    className="btn-round"
                    onClick={() => onSend()}
                    disabled={!isCheck || (showOtp && timer !== 0) || !phone}
                    color="success"
                  >
                    {showOtp && timer
                      ? showTime(timer)
                      : isResend
                      ? "Resend"
                      : "Send OTP"}
                  </Button>
                </Form>
                <div className="box-otp" hidden={!showOtp}>
                  <label>Enter OTP to login</label>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    containerStyle="otp-input"
                    numInputs={4}
                    inputType="number"
                    renderInput={(props) => <input {...props} />}
                  />
                  <div className="form-control-feedback" hidden={!isError}>
                    Incorrect OTP
                  </div>
                </div>
                <FormGroup check>
                  <Label check>
                    <Input
                      defaultValue=""
                      type="checkbox"
                      onChange={() => setIsCheck(!isCheck)}
                      checked={isCheck}
                    />
                    <span className="form-check-sign" />
                  </Label>
                  <p>
                    By clicking this you agree to all terms and conditions and
                    give{" "}
                    <a href="wnnr.in" target="_blank">
                      {" "}
                      wnnr.in
                    </a>{" "}
                    permission to send you marketing messages from time to time.
                    For all Terms and Conditions,{" "}
                    <a href="/policy.pdf" download target="_blank">
                      click here
                    </a>
                  </p>
                </FormGroup>
                <Button
                  hidden={!showOtp}
                  block
                  className="btn-custom btn-round"
                  onClick={() => onVerify()}
                  disabled={!isCheck || !otp}
                  color="success"
                >
                  Validate OTP
                </Button>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="text-center footer-copyright">
              <div>(C) TWO TREES VENTURES PVT LTD</div>
              <div>BANGALORE 2024</div>
              <div>trigam@wnnr.in</div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;
