import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
// import Country from "@assets/Json/Country_and_cites.json";
import IndiaCountry from "@assets/Json/India_cities.json";
import moment from "moment";
import Select from "react-select";
// Core components
import Img from "@assets/img";
import BreadcrumbNav from "@components/BreadcrumbNav/BreadcrumbNav";
import HeaderBar from "@components/HeaderBar";
import Navigate from "@components/Navigator";
import PopUp from "@components/PopUp";
import ReferFriend from "@components/ReferFriend";
import Sidebar from "@components/SideBar";
import { Errors } from "@const/error";
import {
  DataSideBar,
  DataSideDashboardBar,
  TicketSource,
  TicketType,
} from "@const/profile";
import { updateUser } from "@store/authReducer";
import { useDispatch } from "react-redux";

//Api Call
import { getProfile, receiveTickets, updateProfile } from "@network/Profile";

/** Style */
import TabSide from "@components/TabSide";
import { toast } from "react-toastify";
import * as S from "./style";
import { ThreeCircles } from "react-loader-spinner";
import useDataSideBar from "src/hooks/useDataSideBar";
import { Helmet } from "react-helmet";

interface IFormInput {
  username: string;
  fullName: string;
  gender: string;
  dateOfBirth?: string;
  email: string;
  phoneNumber: string;
  country: string;
  province: string;
  address: string
}

const errorMessage: Record<string, string> = {
  required: "Please input username",
  pattern: "NOT allowed characters",
};

const optionsGender = [
  {
    value: "MALE",
    label: "Male",
  },
  {
    value: "FEMALE",
    label: "Female",
  },
  {
    value: "OTHER",
    label: "Other",
  },
];

const optionsProvince = IndiaCountry.cities?.map((province) => ({
  value: province,
  label: province,
}));

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const [country] = useState<string>(IndiaCountry.name);
  const [navbar, setNavbar] = useState(false);
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [profileValues, setProfileValues] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { otherSideData } = useDataSideBar();

  const canEdit = !isDisabled && !isLoading;

  const defaultFormValues = useMemo(() => {
    if (!profileValues)
      return {
        username: "",
        fullName: "",
        dateOfBirth: "",
        gender: "",
        email: "",
        province: "",
        address: "",
      };
    const { username, fullName, dateOfBirth, gender, email, province, address } =
      profileValues;
    return {
      username,
      fullName,
      dateOfBirth,
      gender,
      email,
      province,
      address
    };
  }, [profileValues]);

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues,
  });
  const watchGender = watch("gender");
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  const maxDate = currentDate.toISOString().split("T")[0];

  useEffect(() => {
    reset(defaultFormValues);
  }, [defaultFormValues, reset]);

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    console.log('data', data);
    
    setIsDisabled(false);
    if (!errors || (Object.keys(errors).length === 0 && !isDisabled)) {
      const dateOfBirth = moment(data.dateOfBirth).format("YYYY/MM/DD");
      const profileDataUpdate: any = {
        ...data,
        dateOfBirth,
      };
      try {
        setIsLoading(true);
        const updateProfileRes = await updateProfile(profileDataUpdate);
        if (updateProfileRes.status === "error") {
          toast.error(updateProfileRes.message);
          return;
        }
        const newData = await getProfile();

        dispatch(updateUser({ user: newData.data.profile }));
        receiveTickets({
          title: "Profile ticket",
          type: TicketType.DAILY,
          source: TicketSource.COMPLETE_PROFILE,
          expiredDate: moment().add(7, "day").toISOString(),
        }).then((data) => {
          if (data.code === 200) setVisiblePopup(true);
        });
        toast.success("Update profile successfully");

        setIsDisabled(true);
      } catch (error: any) {
        toast.error(Errors.PROFILE_UPDATED_ERROR);
        setIsDisabled(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const changeBackGround = () => {
    if (window.scrollY > 20) {
      setNavbar(true);
    } else setNavbar(false);
  };
  window.addEventListener("scroll", changeBackGround);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProfile();
        setProfileValues(response.data.profile);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchData();
  }, []);

  const HandleCancel = () => {
    setVisiblePopup(false);
  };

  return (
    <>
      <Helmet>
        <title>TWO-Profile</title>
      </Helmet>
      <S.Wrapper>
        <Sidebar
          sideData={DataSideDashboardBar}
          otherSideData={otherSideData}
        />
        <S.ContainerNavigate className={navbar ? "activeBg" : ""}>
          <Navigate />
          <BreadcrumbNav />
          <TabSide tabData={DataSideBar} />
        </S.ContainerNavigate>
        <S.ContainerWrapper>
          <HeaderBar backToDashboard={true} title="Back to Dashboard" />
          <S.Container>
            <p className="title">Profile Management</p>
            <S.FromSubmit onSubmit={handleSubmit(onSubmit)}>
              <label>User Name</label>
              <input
                disabled={!canEdit}
                {...register("username", {
                  pattern:
                    /^(?!.*[\\/]).*[a-zA-Z0-9!@#$%^&*()-_=+`~{}[\]|;:'",.<>? ]*$/,
                })}
                maxLength={50}
                aria-invalid={errors.username ? "true" : "false"}
                placeholder="Input your username"
              />
              {errors.username && (
                <p role="alert" style={{ color: "red" }}>
                  {errorMessage[errors.username.type]}
                </p>
              )}
              <label>Full Name</label>
              <input
                disabled={!canEdit}
                {...register("fullName", {
                  pattern: /^[a-zA-Z0-9\s]+$/,
                })}
                maxLength={50}
                placeholder="Input your full name"
              />
              {errors.fullName && (
                <p role="alert" style={{ color: "red" }}>
                  {errorMessage[errors.fullName.type]}
                </p>
              )}
              <S.ContainerInput>
                <div>
                  <label>Date of birth</label>
                  <input
                    {...register("dateOfBirth")}
                    type="date"
                    name="trip-start"
                    max={maxDate}
                    disabled={!canEdit}
                    onChange={(e) => setValue("dateOfBirth", e.target.value)}
                  />
                  {errors.dateOfBirth && (
                    <p role="alert" style={{ color: "red" }}>
                      {errorMessage[errors.dateOfBirth.type]}
                    </p>
                  )}
                </div>
                <div>
                  <label>Gender</label>
                  <Select
                    {...register("gender")}
                    isDisabled={!canEdit}
                    className="basic-single"
                    classNamePrefix="select"
                    options={optionsGender}
                    onChange={(selectedOption) =>
                      setValue("gender", selectedOption?.value ?? "")
                    }
                    placeholder={watchGender ?? "Select your gender"}
                  />
                </div>
              </S.ContainerInput>
              <S.ContainerInput>
                <div>
                  <label>Email</label>
                  <input
                    disabled={!canEdit}
                    {...register("email", {
                      pattern: /^[a-zA-Z0-9.@]+@[a-zA-Z0-9.]+\.[a-zA-Z0-9]+$/,
                    })}
                    maxLength={50}
                    placeholder="Input your email"
                  />
                  {errors.email && (
                    <p role="alert" style={{ color: "red" }}>
                      Invalid email address
                    </p>
                  )}
                </div>
                <div>
                  <label>Phone Number</label>
                  <input
                    className={isDisabled ? "" : "input-phone"}
                    disabled={true}
                    placeholder="Input your mobile number"
                    value={profileValues?.phoneNumber}
                  />
                </div>
              </S.ContainerInput>
              <S.ContainerInput>
                <div>
                  <label>Country</label>
                  <Select
                    isDisabled={true}
                    className={
                      isDisabled ? "basic-single" : "basic-single disable-input"
                    }
                    classNamePrefix="select"
                    value={country}
                    placeholder={country}
                  />
                </div>
                <div>
                  <label>State</label>

                  <Select
                    {...register("province")}
                    isDisabled={!canEdit}
                    className="basic-single"
                    classNamePrefix="select"
                    options={optionsProvince}
                    onChange={(selectedOption) =>
                      setValue("province", selectedOption?.value ?? "")
                    }
                    placeholder={profileValues?.province}
                  />
                </div>
              </S.ContainerInput>
              <S.DivAddress>
              <label>Address</label>
              <input
                disabled={!canEdit}
                {...register("address")}
                maxLength={500}
                placeholder="Input your address"
              />
              {errors.address && (
                <p role="alert" style={{ color: "red" }}>
                  {errorMessage[errors.address.type]}
                </p>
              )}
              </S.DivAddress>

              <button className="btn-submit" type="submit">
                {isDisabled ? "Edit" : "Save"}
                {isLoading && (
                  <div style={{ marginLeft: 20 }}>
                    <ThreeCircles
                      visible={true}
                      height={20}
                      width={20}
                      color="#fff"
                      ariaLabel="three-circles-loading"
                    />
                  </div>
                )}
              </button>
            </S.FromSubmit>
          </S.Container>
        </S.ContainerWrapper>
        <ReferFriend />

        <PopUp
          visible={visiblePopup}
          title="Warning"
          footer={true}
          onCancel={() => HandleCancel()}
          onOk={() => setVisiblePopup(false)}
          okText="Continue"
          cancelText="Cancel"
        >
          <S.PopUpContainer>
            <div className="popup-title">
              Thanks for completing profile.
              <br /> You have got 1 free ticket!
            </div>
            <div>
              <img src={Img.StarIcon} alt="fire" width={132} />
            </div>
          </S.PopUpContainer>
        </PopUp>
      </S.Wrapper>
    </>
  );
};

export default Profile;
