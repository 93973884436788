import Checkbox from "@components/Checkbox";
import Radio from "@components/Radio";
import { IQuestionType, QuestionInterface, QuestionType } from "@network/Questions/type";
import React, { useState } from "react";
import * as S from "../style";

const QuestionContainer = ({
  question,
  index,
  handleChangeAnswer,
}: {
  question: QuestionInterface;
  index: number;
  handleChangeAnswer: (questionId: number, answers: string[]) => void;
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);

  const onChangeAnswer = (answer: string) => {
    let selectAnswer = [...selectedAnswers];
    if (question.type === QuestionType.MULTIPLE_SELECT) {
      if (selectedAnswers.includes(answer)) {
        selectAnswer = selectAnswer.filter((a) => a !== answer);
      } else {
        selectAnswer.push(answer);
      }
    } else {
      selectAnswer = [answer];
    }
    setSelectedAnswers(selectAnswer);
    handleChangeAnswer(question.id, selectAnswer);
  };

  return (
    <S.QuestionContainer>
      <S.Question>{`${index + 1}. ${question.title}`}</S.Question>
      <S.AnswerGroup>
        {question?.answers?.map((answer) => {
          const isChecked = selectedAnswers?.includes(answer.id);

          if (question.type === QuestionType.MULTIPLE_SELECT) {
            return (
              <Checkbox
                checked={isChecked}
                key={question.id}
                label={answer.content}
                name={question.id.toString()}
                id={answer.id}
                onChange={() => onChangeAnswer(answer.id)}
              />
            );
          }
          const haveImage = answer.type === IQuestionType.IMAGE
          if(haveImage) return <img key={question.id} src={answer.content} className="img-question"/>
          return (
            <>
            <Radio
              checked={isChecked}
              key={question.id}
              label={answer.content}
              name={question.id.toString()}
              id={answer.id}
              onChange={() => onChangeAnswer(answer.id)}
            />
            </>
          );
        })}
      </S.AnswerGroup>
    </S.QuestionContainer>
  );
};

export default QuestionContainer;
