import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10400;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
`;

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const Close = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: linear-gradient(
    341.1deg,
    rgba(110, 226, 245, 0.3) -28.66%,
    rgba(100, 84, 240, 0.3) 87.23%
  );

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  .img {
    margin-bottom: 30px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #fff;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 320px) {
    margin-right: 10px;
  }

  &.header {
    justify-content: space-between;
    border-bottom: solid 1px #7f7f7f;
    padding: 16px;
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: radial-gradient(
    50.55% 79.92% at 70.55% 52.33%,
    #000041 0%,
    #000030 49.5%,
    #00002a 100%
  );
  z-index: 100;
  max-width: fit-content;
  vertical-align: middle;
  p {
    text-align: center;
  }
  &.height-default {
    min-height: 230px;
  }
  &.w-large {
    max-width: 800px;
    width: 800px;
  }
`;

export const ModalTitle = styled.p`
  color: #1d1f21;
  font-size: 20px;
  position: relative;
  margin-bottom: 15px;
`;

export const ModalContent = styled.div`
  color: #8d8d8d;
  font-size: 16px;
  /* margin-bottom: 22px; */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #08ba98;
  line-height: 1.69;
  flex: 1;
  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ModalButton = styled.div`
  display: flex;
  justify-content: center;
  button {
    font-size: 14px;
    padding: 15px 0;
    border-radius: 5px;
    text-align: center;
  }
`;

export const ModalCancel = styled.button`
  border: solid 2px #1d1f21;
  color: #1d1f21;
  width: 136px;
  margin-right: 10px;
  background-color: white;
`;

export const ModalConfirm = styled.button`
  background-color: #1d1f21;
  color: white;
  border: none;
  width: 140px;
`;

export const ButtonStyled = styled.button`
  color: #fff;
  background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
  text-align: center;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 7px 33px;
  border-radius: 6px;
  border: none;

  &.not-allowed {
    cursor: not-allowed;
  }

  @media screen and (max-width: 768px) {
    width: auto;
  }
`;

export const ButtonCancel = styled.button`
  text-align: center;
  color: #00ffed;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 7px 15.5px;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #00ffed;
  margin-right: 16px;
  &:hover {
    color: #00ffed;
  }
`;

export const FooterModal = styled.div`
  display: flex;
  padding: 20px 70px;
  justify-content: center;
  button {
    margin-bottom: 0;
  }
`;
