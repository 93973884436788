import img from "@assets/img";
import { routes } from "@const/routes";
import { EAppAction } from "@store/appReducer";
import { RootState } from "@store/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useDataSideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: RootState) => state.auth.token);
  const onOpenReferPopup = () => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }
    dispatch({
      type: EAppAction.SHOW_REFER_POPUP,
      payload: true,
    });
  };
  const otherSideData = [
    {
      id: 5,
      title: "Refer a friend",
      link: "",
      logo: img.ReferFriendIcon,
      otherAction: onOpenReferPopup,
    },
  ];
  return {
    otherSideData,
  };
};

export default useDataSideBar;
