import React, { useEffect, useState } from "react";

// Core components
import BreadcrumbNav from "@components/BreadcrumbNav/BreadcrumbNav";
import HeaderBar from "@components/HeaderBar";
import Navigate from "@components/Navigator";
import ReferFriend from "@components/ReferFriend";
import Sidebar from "@components/SideBar";

//Css Here
import TabSide from "@components/TabSide";
import { DataSideBar, DataSideDashboardBar } from "@const/profile";
import { getRaffle } from "@network/Raffle";
import { Raffle } from "@type/Raffle";

//Css Here
import { SCREEN } from "@type/index";
import RaffleSwiperRender from "../Dashboard/Components/RaffleSwipeRender";
import * as S from "./style";
import useDataSideBar from "src/hooks/useDataSideBar";
import { Helmet } from "react-helmet";

interface RaffleData {
  raffles: {
    nextRaffle: Raffle;
    daily: {
      raffles: Raffle[];
      total: number;
    };
    weekly: {
      raffles: Raffle[];
      total: number;
    };
    monthly: {
      raffles: Raffle[];
      total: number;
    };
    quarterly: {
      raffles: Raffle[];
      total: number;
    };
    halfYear: {
      raffles: Raffle[];
      total: number;
    };
    annually: {
      raffles: Raffle[];
      total: number;
    };
  };
}

const FavoriteRaffle: React.FC = () => {
  const [raffleData, setRaffleData] = useState<RaffleData>();
  const [navbar, setNavbar] = useState(false);
  const limit = 5;
  const { otherSideData } = useDataSideBar();

  useEffect(() => {
    fetchAll();
  }, []);

  const fetchAll = async () => {
    const data = {
      limit: limit,
      offset: 0,
      type: "ALL",
      screen: SCREEN.FAVORITE,
    };
    try {
      const response = await getRaffle(data);
      setRaffleData(response.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const changeBackGround = () => {
    if (window.scrollY > 20) {
      setNavbar(true);
    } else setNavbar(false);
  };
  window.addEventListener("scroll", changeBackGround);

  return (
    <>
      <Helmet>
        <title>TWO-Favorite Raffle</title>
      </Helmet>
      <S.Wrapper>
        <Sidebar
          sideData={DataSideDashboardBar}
          otherSideData={otherSideData}
        />
        <S.ContainerNavigate className={navbar ? "activeBg" : ""}>
          <Navigate />
          <BreadcrumbNav />
          <TabSide tabData={DataSideBar} />
        </S.ContainerNavigate>
        <S.ContainerWrapper>
          <HeaderBar backToDashboard={true} title="Back to Dashboard" />
          <S.RaffleContainer>
            <p className="title">My Favorite Raffle</p>
            <RaffleSwiperRender raffleData={raffleData} />
          </S.RaffleContainer>
        </S.ContainerWrapper>
        <ReferFriend />
      </S.Wrapper>
    </>
  );
};

export default FavoriteRaffle;
