import React, { useEffect, useRef } from "react";
import { Container } from "reactstrap";
import Img from "@assets/img";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import DashboardConstant from "@views/Pages/Dashboard/landing-image.constant";


export const ImgBox = styled.img`
  width: 100%;
`;

export const ThumbNailRobot = styled.img`
  display: none;
  width: fit-content;
   @media (max-width: 768px) {
       display: block;
  }
`

export const Paragraph = styled.p<{ gradient?: boolean }>`
  width: 50%;
  font-size: 56px;
  font-weight: 700;
  text-align: left;
  margin-bottom: -15px;
  @media (max-width: 768px) {
    font-size: 32px;
    width: 100%; 
    text-align: center;
  }
  ${(p) =>
    p.gradient
      ? "background: linear-gradient(113.96deg, #64E8DE 0%, #8A64EB 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent;"
      : ""}
`;

const WrapperParagraph = styled.div`
    display: flex;
     @media (max-width: 768px) {
      justify-content: center;
  }
`;
const StyledParagraph = styled.p`
  text-wrap: no-wrap;
  margin-top: 16px;
  color: ${(props) => props.color || 'black'}; 
  font-size: 24px;
  font-weight: 700; 
  margin-left: 5px;
   @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const BannerContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  }
 
`

const BannerImg = styled.img`
  height: 60px !important;
  width: 100vw;

`

export const Button = styled.button`
  background: linear-gradient(163.96deg, #c7d2fe 50%, #5650de 100%);
  color: #082f49ed;
  border: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-top: 20px;
  white-space: nowrap;
  border-radius: 30px;
  margin-left: 120px;
   @media (max-width: 768px) {
   margin-left: 0;
  }
`;

const RobotImgContainer = styled.div`
  display: flex;
  align-items: end;
  height: 80vh;
   @media (max-width: 768px) {
    display: none;
  }
`;

const Header: React.FC = () => {
  const navigate = useNavigate();
  const pageHeader = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateScroll = () => {
      if (window.innerWidth < 991 && pageHeader.current) {
        const windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform = `translate3d(0, ${windowScrollTop}px, 0)`;
      }
    };

    window.addEventListener("scroll", updateScroll);
    return () => {
      window.removeEventListener("scroll", updateScroll);
    };
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${DashboardConstant.BackgroundImage})`,
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <RobotImgContainer>
          <ImgBox src={Img.RobotDashboard} />
        </RobotImgContainer>
        <Container>
          <div className="motto text-center">
            <Paragraph>What are you going to win today?</Paragraph>
            <WrapperParagraph>
            <StyledParagraph color="#ea580c">FREE GAMES.</StyledParagraph>
            <StyledParagraph color="#a3e635">BIG REWARDS.</StyledParagraph>
            </WrapperParagraph>
            <WrapperParagraph>
                <ThumbNailRobot src={DashboardConstant.Robot} />
            </WrapperParagraph>
            <WrapperParagraph>
              <Button
                type="button"
                onClick={() => navigate("/dashboard")}
                className="btn-lg btn btn-primary"
              >
                Play Now!
              </Button>
            </WrapperParagraph>
            <BannerContainer>
               <BannerImg src={DashboardConstant.Banner} />
            </BannerContainer>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
