import styled from "styled-components";

export const Wrapper = styled.div`
  width: 610px;
  border-radius: 12px;
  padding: 20px 40px;
  border: 1px solid #00ffed;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: start;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Robot = styled.div`
  img {
    height: 185px;
    width: auto;
    @media (max-width: 768px) {
      height: 110px;
      width: auto;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }
  span {
    margin-top: 10px;
    font-size: 30px;
    font-weight: 700;
  }
  a {
    border: 1px solid #00b8ba;
    width: fit-content;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 700;
    background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
    padding: 5px 15px;
    border-radius: 8px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-shadow: 0px 2px 0px 0px #0000000b;
  }
`;
