import Img from "@assets/img";
import ReferFriendPopup from "@components/ReferFriendPopup";
import { RootState } from "@store/store";
import React from "react";
import { useSelector } from "react-redux";
import * as S from "./style";
import { useDispatch } from "react-redux";
import { EAppAction } from "@store/appReducer";

const ReferFriend = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: RootState) => state.auth.token);
  const isOpenReferPopup = useSelector((state: RootState) =>
    Boolean(state.app.isShowReferPopup)
  );

  const onOpenReferPopup = () => {
    dispatch({
      type: EAppAction.SHOW_REFER_POPUP,
      payload: true,
    });
  };

  return <>{isLoggedIn && <ReferFriendPopup isOpen={isOpenReferPopup} />}</>;
};

export default ReferFriend;
