import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// Core components

//Css Here
import DashboardBox from "@components/DashboardBox";
import { RaffleDataBased } from "@type/Raffle";
import * as S from "./style";

//Css Here
import { getRaffle } from "@network/Raffle";
import { SCREEN } from "@type/index";
import RaffleSwiperRender from "./Components/RaffleSwipeRender";

const Dashboard = ({ title }: { title: string }) => {
  const [raffleData, setRaffleData] = useState<RaffleDataBased>();
  const [nextTime, setNextTime] = useState();

  const nextRaffle = {
    title: "The next raffle starts in:",
    raffle: nextTime,
    buttonText: "Join Raffle Now",
  };
  const nextBlankRaffle = {
    title: "The next raffle starts in:",
    blank: "Waiting",
  };

  const questionair = {
    title: "Join questionair to get free stickets",
    buttonText: "Join Questionair",
  };

  const limit = 5;

  useEffect(() => {
    const fetchAll = async () => {
      const data = {
        limit: limit,
        offset: 0,
        type: "ALL",
        screen: SCREEN.DASHBOARD,
      };
      try {
        const response = await getRaffle(data);
        setNextTime(response.data.raffles.nextRaffle);
        setRaffleData(response.data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };
    fetchAll();
  }, []);

  return (
    <>
      <Helmet>
        <title>TWO-Raffles Lobby</title>
      </Helmet>
      <S.Title>{title}</S.Title>
      <S.Top>
        {nextTime ? (
          <DashboardBox {...nextRaffle} />
        ) : (
          <DashboardBox {...nextBlankRaffle} />
        )}
      </S.Top>

      <RaffleSwiperRender raffleData={raffleData} />
    </>
  );
};

export default Dashboard;
