import PopUp, { SizeType } from "@components/PopUp";
import { Errors } from "@const/error";
import { getMilestoneById, submitAnswers } from "@network/Questions";
import {
  QuestionParams,
  QuestionReqParams,
  QuestionResData,
} from "@network/Questions/type";
import { updateNotice } from "@store/noticeReducer";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import * as S from "../style";
import * as _ from "lodash";
import { USER_ANSWER_FOR } from "@type/index";
import QuestionContainer from "./QuestionContainer";
import AlertPopup from "@components/AlertPopup";
import Img from "@assets/img";
import { useNavigate } from "react-router-dom";
import { routes } from "@const/routes";

export const Paragraph = styled.p`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 20px;
`;

export const QuestionaryPopup = ({
  isOpen,
  onDismiss,
  onFinish,
  raffleId,
  milestoneId,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  onFinish?: () => void;
  raffleId: number;
  milestoneId?: number;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [questionData, setQuestionData] = useState<QuestionResData>();
  const [questions, setQuestions] = useState<QuestionReqParams[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isOpenCompletedAlert, setIsOpenCompletedAlert] = useState(false);
  const [ticketCreated, setTicketCreated] = useState(false);

  const onCancel = () => {
    setQuestions([]);
    onDismiss();
  };

  const handleChangeAnswer = useCallback(
    (questionId: number, answerIds: string[]) => {
      const index = _.findIndex(questions, ["id", questionId]);
      if (index !== -1) questions.splice(index, 1);
      if (answerIds?.length) questions.push({ id: questionId, answerIds });
      setQuestions(questions);
      setDisabled(_.size(questionData?.questions) !== _.size(questions));
    },
    [questions, questionData]
  );

  const onSubmitQuestionForm = async () => {
    if (disabled || !questions || !milestoneId) return;

    const params: QuestionParams = {
      milestoneId,
      questions: questions,
      refId: raffleId,
      userAnswerFor: USER_ANSWER_FOR.RAFFLE,
    };

    try {
      const res = await submitAnswers(params);
      if (res.data.submitAnswer) {
        setIsOpenCompletedAlert(true);
        setTicketCreated(res.data.ticketCreated);
      } else {
        updateNotice(
          {
            type: "ERROR",
            content: Errors.SUBMIT,
          },
          dispatch
        );
      }
    } catch (error) {
      console.error(error);
      updateNotice(
        {
          type: "ERROR",
          content: Errors.SUBMIT,
        },
        dispatch
      );
    }
  };

  useEffect(() => {
    async function getQuestions() {
      if (!raffleId) return;
      const res = await getMilestoneById(raffleId);
      setQuestionData(res.data);
    }
    getQuestions();
  }, [raffleId]);

  return (
    <>
      <PopUp
        visible={isOpen && !isOpenCompletedAlert}
        title={questionData?.title || ""}
        footer
        onOk={onSubmitQuestionForm}
        onCancel={onCancel}
        okText="Submit"
        size={SizeType.LARGE}
        isOnlyOkButton
        isDisabledOk={disabled}
      >
        <S.Content>
          <p className="note_text">
            <span className="note_text-red">*</span> Please complete the survey
            to participate in the raffle!
          </p>
          {questionData?.questions?.map((question, index) => {
            return (
              <React.Fragment key={question.id}>
                <QuestionContainer
                  question={question}
                  index={index}
                  handleChangeAnswer={handleChangeAnswer}
                />
              </React.Fragment>
            );
          })}
        </S.Content>
      </PopUp>
      <AlertPopup
        isOpen={isOpenCompletedAlert}
        header="Congratulation"
        title={
          <>
            Thanks for submitting the survey!
            <br />
            {ticketCreated
              ? "Congratulation you have got 1 free ticket!"
              : "You have got 3 tickets today!"}
          </>
        }
        icon={Img.StarIcon}
        leftButton={{
          title: "View ticket list",
          onClick: () => navigate(routes.ticket),
        }}
        rightButton={{
          title: "Join Raffle",
          onClick: () => onFinish?.(),
        }}
        onDismiss={onDismiss}
      />
    </>
  );
};
