import { USER_ANSWER_FOR } from "@type/index";

export enum QuestionType {
  SINGLE_SELECT = "SINGLE_SELECT",
  MULTIPLE_SELECT = "MULTIPLE_SELECT",
}

export enum IQuestionType {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT'
}
type IAnswer = {
  content: string;
  id: string;
  type: IQuestionType
};
export interface QuestionInterface {
  id: number;
  title: string;
  type: QuestionType;
  answers: IAnswer[];
}

export interface QuestionResData {
  title: string;
  questions: QuestionInterface[];
}

export type QuestionReqParams = {
  id: number;
  answerIds: string[];
};

export type QuestionParams = {
  milestoneId: number;
  refId: number;
  questions: QuestionReqParams[];
  userAnswerFor: USER_ANSWER_FOR;
};
