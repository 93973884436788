import { ClockCircleIcon } from "@components/Icons";
import moment from "moment";
import React from "react";
import { DatetimepickerProps } from "react-datetime";
import * as S from "./style";
import { FILTER_DATE_TIME_FORMAT } from "@const/index";

type DateTimeSelectorProps = React.ComponentPropsWithoutRef<"input"> & {
  label: string;
  wrapperClassName?: string;
  onChange: (value: string) => void;
  value?: DatetimepickerProps["value"];
};

const DateTimeSelector = ({
  label,
  wrapperClassName,
  onChange,
  value,
  ...rest
}: DateTimeSelectorProps) => {
  return (
    <S.Wrapper className={wrapperClassName}>
      <label htmlFor="datetime" className="datetime-label">
        {label}
      </label>
      <div className="input-datetime-wrap">
        <S.DateTimePickerInput
          type="text"
          className="input-datetime"
          value={value ? moment(value).format(FILTER_DATE_TIME_FORMAT) : ""}
          placeholder="Select date and time"
          readOnly
        />
        <S.DateTimePickerInput
          type="datetime-local"
          className="input-calendar"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          {...rest}
        />
        <ClockCircleIcon className="input_datetime_icon" />
      </div>
    </S.Wrapper>
  );
};

export default DateTimeSelector;
