import moment from "moment";

export const calculateTimeRemaining = (time: Date) => {
  const now = moment();
  const target = moment(time);
  const duration = moment.duration(target.diff(now));
  return {
    days: duration.days(),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  };
};
export const randomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const convertTime = (num: number) => {
  const hours = Math.floor(num / (60 * 60));
  num %= 60 * 60;
  const minutes = Math.floor(num / 60);
  const seconds = num % 60;

  const h = hours.toString().padStart(2, "0");
  const m = minutes.toString().padStart(2, "0");
  const s = seconds.toString().padStart(2, "0");

  return [h, m, s].join(":");
};

export const timer = (time?: Date, exTime?: Date) => {
  if (moment(exTime).unix() < moment().unix()) {
    return "Close";
  }

  const second = moment(time).unix() - moment().unix();

  if (second < 60) {
    return "Happening";
  }

  return convertTime(second);
};
