import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import PopUp from "@components/PopUp";
import { clearStateAndPersist } from "@store/store";
import Img from "@assets/img";

import * as S from "./style";

const Navigate: React.FC = () => {
  const navigate = useNavigate();
  const [isClick, setIsClick] = useState(false);
  const [visiblePopup, setVisiblePopup] = useState(false);

  const handleNavigate = (tab: string) => {
    navigate(tab);
  };

  const logout = () => {
    setVisiblePopup(false);
    clearStateAndPersist();
    navigate("/");
  };
  return (
    <S.Wrapper>
      <S.Container>
        <img src={Img.Logo} alt="Logo" width={158} height={"auto"} />
        <S.Hamburger onClick={() => setIsClick(!isClick)}>
          <img src={Img.hamburgerLogo} alt="Logo" width={32} height={"auto"} />
        </S.Hamburger>
      </S.Container>
      <S.NaviMoblie className={isClick ? "display" : "none"}>
        <S.Close onClick={() => setIsClick(!isClick)}>
          <img src={Img.closeLogo} alt="Logo" width={32} height={"auto"} />
        </S.Close>
        <S.Content>
          <h1>Raffle Participation</h1>
          <p onClick={() => handleNavigate("/dashboard")}>Raffle Lobby</p>
          <p onClick={() => handleNavigate("/raffle-result")}>Raffle Result</p>
          <p>Prize Redemption Guidance</p>
          <p onClick={() => handleNavigate("/profile")}>My Profile</p>
        </S.Content>
        <S.User>
          <p onClick={() => handleNavigate("/profile")}>Profile</p>
          <p onClick={() => setVisiblePopup(true)}>Log out</p>
        </S.User>
      </S.NaviMoblie>
      <PopUp
        visible={visiblePopup}
        title="Log out"
        footer={true}
        onOk={() => logout()}
        onCancel={() => setVisiblePopup(false)}
        okText="No"
        cancelText="Yes"
      >
        <S.PopUpContainer>
          <div className="popup-title">Do you want to logout this session?</div>
          <div>
            <img src={Img.BullsEye} alt="fire" width={132} />
          </div>
        </S.PopUpContainer>
      </PopUp>
    </S.Wrapper>
  );
};

export default Navigate;
