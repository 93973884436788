import instanceAuth from "@network/auth";
import { ParamType, RaffleParams } from "./type";
import buildQuery from "@utils/BuildQuery";
import axios from "axios";
import instance from "@network/api";

export async function getRaffleById(raffleId: number, params?: ParamType) {
  const instance = instanceAuth();
  const res = await instance.get(buildQuery(`/raffles/${raffleId}`, params));

  return res.data;
}

export async function endSession(raffleId: number) {
  const instance = instanceAuth();
  const res = await instance.post(`/raffles/${raffleId}/end-session`);

  return res.data;
}

export async function getParticipants(param: ParamType) {
  const instance = instanceAuth();
  const res = await instance.get(
    `/raffles/${param.id}/participants/?limit=${param.limit}&offset=${param.offset}`
  );

  return res.data;
}
export async function getRewards(param: ParamType) {
  const instance = instanceAuth();
  const res = await instance.get(`/raffles/${param.id}/rewards`);

  return res.data;
}
export async function getLuckyNumbers(param: ParamType) {
  const instance = instanceAuth();
  const res = await instance.get(
    `/raffles/${param.id}/lucky-numbers?screen=${param.screen}`
  );

  return res.data;
}

export async function getRaffle(data: RaffleParams, signal?: AbortSignal) {
  const res = await instance.get(buildQuery("/raffles", data));
  return res.data;
}
