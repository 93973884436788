import Img from "@assets/img";
import Banner from "@assets/img/dashboard/bannerDashboard.png"
import BannerDesktop from "@assets/img/dashboard/bannerDashboarDesktop.png"


const Logo = Img.Logo
const BackgroundImage = Img.Header
const Robot = Img.Robot

export default {
    Logo,
    BackgroundImage, 
    Robot,
    Banner,
    // Resolution: 4:1 (2069px x 463px)
    BannerDesktop
}