import styled from "styled-components";
import Img from "@assets/img";

export const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  display: flex;
  background-image: url(${Img.bgDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    background-image: url(${Img.bgMobile});
  }
  .activeBg {
    background: #101355;
  }
`;
export const Container = styled.div`
  width: 880px;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  .title {
    margin: 10px 0;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const ContainerWrapper = styled.div`
  width: calc(100% - 240px);
  padding: 16px 40px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 16px;
    width: 100%;
  }
`;

export const RaffleContainer = styled.div`
  p {
    margin: 0 0 10px 0;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
  }
  @media (max-width: 768px) {
    p {
      font-size: 20px;
    }
  }
`;

export const ContainerNavigate = styled.div`
  width: 100%;
  display: none;
  @media screen and (max-width: 768px) {
    position: sticky;
    top: 0;
    background-color: transparent;
    z-index: 99;
    display: block;
    padding-bottom: 5px;
  }
`;

export const DivAddress = styled.div`
  margin-top: 20px;
`;

export const Content = styled.div`
  margin-top: 20px;
  width: 100%;
  gap: 8px;
  h3 {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 700;
  }
  p {
    margin: 10px 0;
    font-size: 16px;
    color: #b3b3b3;
    font-weight: 700;
  }
  .line {
    margin: 10px 0;
    width: 100%;
    height: 1px;
    background-color: #b3b3b3;
  }
`;
export const Button = styled.button`
  border: 1px solid #fff;
  border-radius: 8px;
  color: #fff;
  padding: 5px 30px;
  background: transparent;
  font-weight: 700;
  font-size: 14px;
  margin: 10px 0;
`;
export const FromSubmit = styled.form`
  width: 100%;
  border: none;
  margin-top: 10px;
  margin-left: 5px;
  label {
    line-height: 2;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 4px;
  }
  input {
    box-sizing: border-box;
    width: 100%;
    border-radius: 6px;
    padding: 10px 12px;
    margin-bottom: 20px;
    font-size: 14px;
    outline: none;
    border: 1px solid #6ee2f54d;
    background: linear-gradient(
      to top,
      rgba(110, 226, 245, 0.3) -28.66%,
      rgba(100, 84, 240, 0.3) 87.23%
    );
    box-shadow: 0px 2px 0px 0px #0000000b;
    color: #fff;
  }
  ::placeholder {
    opacity: 1;
    color: #b3b3b3;
    font-weight: 400px;
  }
  select {
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    /* border: 1px solid #7f7f7f; */
    border: none;
    padding: 10px 10px;
    margin-bottom: 20px;
    font-size: 14px;
    outline: none;
    border: 1px solid #6ee2f54d;
    background: linear-gradient(
      to top,
      rgba(110, 226, 245, 0.3) -28.66%,
      rgba(100, 84, 240, 0.3) 87.23%
    );
    color: #fff;
    option {
      background-color: #000;
    }
    &::placeholder {
      color: #fff;
    }
  }
  input[type="submit"],
  button.btn-submit {
    background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
    width: 100%;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    border-radius: 6px;
  }
  .btn-submit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }
  input:disabled {
    /* color: #7f7f7f; */
  }
  .radio {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input[type="radio"] {
      width: auto;
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label {
      position: relative;
      padding-left: 25px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #fff;
    }
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 25px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
    }
    [type="radio"]:checked + label:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #fff;
      border-radius: 50%;
      background: #000;
    }
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 2px solid #4d4d4d;
      border-radius: 100%;
      background: #000;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 10px;
      height: 10px;
      background: #fff;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container .react-datepicker__calendar-icon {
    top: 4px;
    right: 0;
  }
`;

export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  .active {
    color: #00e5e8;
    border-bottom: 1px solid #00e5e8;
    font-weight: 700;
  }
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b3b3b3;
    padding: 12px 0;
    border-bottom: 1px solid #b3b3b3;
  }
`;
export const TabProfile = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const ContainerInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-weight: 400;
  .input-phone {
    background: linear-gradient(113.96deg, #64e8de 0%, #8a64eb 100%);
    border: none;
  }
  .calender-icon {
    position: absolute;
    top: 4px;
    right: 0px;
  }
  .css-t3ipsp-control {
    background-color: transparent;
    color: #fff;
  }
  .css-1u9des2-indicatorSeparator {
    display: none;
  }
  .css-13cymwt-control {
    border: 1px solid #6ee2f54d;
    background: linear-gradient(
      to top,
      rgba(110, 226, 245, 0.3) -28.66%,
      rgba(100, 84, 240, 0.3) 87.23%
    );
    &:hover {
      border: 1px solid #6ee2f54d;
    }
  }
  .css-qbdosj-Input {
    cursor: pointer;
  }
  .css-d7l1ni-option {
    cursor: pointer;
    background: linear-gradient(
      341deg,
      rgba(110, 226, 245, 0.3) -28.66%,
      rgba(100, 84, 240, 0.3) 87.23%
    );
  }
  .css-1nmdiq5-menu {
    background-color: #000;
    background: radial-gradient(
      79.92% 50.55% at 70.55% 52.33%,
      #000041 0%,
      #000030 49.5%,
      #00002a 100%
    );
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .disable-input {
    background: linear-gradient(113.96deg, #64e8de 0%, #8a64eb 100%);
    border-radius: 6px;
    border: none;
    div {
      border: none !important;
    }
  }
  .basic-single {
    width: 420px;
    outline: none;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    > input {
      background: linear-gradient(
        to top,
        rgba(110, 226, 245, 0.3) -28.66%,
        rgba(100, 84, 240, 0.3) 87.23%
      );
    }

    .css-1dimb5e-singleValue {
      color: #fff;
      font-size: 14px;
    }
    .css-olqui2-singleValue {
      color: #fff;
      font-size: 14px;
    }
    .select__value-container {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .css-1jqq78o-placeholder {
      color: #fff;
      font-size: 14px;
    }
    .css-16xfy0z-control {
      background: linear-gradient(
        to top,
        rgba(110, 226, 245, 0.3) -28.66%,
        rgba(100, 84, 240, 0.3) 87.23%
      );
      color: #fff;

      border: 1px solid #6ee2f54d;
    }
    .css-894a34-indicatorSeparator {
      display: none;
    }
    .css-166bipr-Input {
      color: #fff;
    }
  }
  label {
    display: block;
  }
  div > input {
    width: 420px;
    box-sizing: border-box;
  }
  select {
    width: 420px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    div > input {
      width: 100%;
    }
    select {
      width: 100%;
    }
    > div {
      width: 100%;
    }
  }
`;

export const WrapperTable = styled.div`
  width: 100%;
  padding: 0 40px;
`;

export const Title = styled.div`
  width: 100%;
  h4 {
    margin: 0 !important;
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
  }
  span {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 8px;
    margin-bottom: 32px;
    background: linear-gradient(113.96deg, #ffcda5 0%, #ee4d5f 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const RaffleSlider = styled.div`
  position: relative;
`;

export const RaffleList = styled.div`
  .swiper {
    position: relative;
  }
`;

export const RaffleItem = styled.div``;

export const Paginate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;

  button {
    display: block;
    padding: 1px 8px;
    border: none;
    background-color: transparent;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #f2f2f2;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .active {
    border: 1px solid #00ffed;
    color: #00ffed;
    border-radius: 6px;
  }
`;

export const PopUpContainer = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  .popup-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 20px 40px;
    color: #fff;
  }
`;

export const NoSelectionBg = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 14px;
    margin-top: 15px;
    font-weight: 400;
  }
`;
