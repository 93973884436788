export interface Raffle {
  id: number;
  raffleId: number;
  title: string;
  type: string;
  status: string;
  startTime: Date;
  expiredDate: Date;
  category: string;
  numberOfTurns: number;
  numberOfParticipants: number;
  thumbnailImg: string;
  bannerImg: string;
  rn: string;
  favorite: boolean;
  color: RaffleColorType;
}

export interface RaffleColorType {
  background?: string;
  title?: string;
  titleAnother?: string;
  titleHeading?: string;
  titleTurnNext?: string;
}

interface RaffleData {
  raffles: any[];
  total: number;
}
export interface RaffleTypeData {
  daily: RaffleData;
  weekly: RaffleData;
  monthly: RaffleData;
  quarterly: RaffleData;
  halfYear: RaffleData;
  annually: RaffleData;
  special: RaffleData;
}
export interface RaffleApi {
  limit: number;
  offset: number;
  type?: string;
  id?: string;
}

export interface RewardRaffle {
  id: number;
  title: number;
  turn: string;
}

export interface ParticipantRaffle {
  id: number;
  fullname: number;
  luckynumber: string;
}
export interface LuckyNumberRaffle {
  number: number;
  ticketName: string;
}

export enum RaffleStatus {
  ELIGIBLE = "ELIGIBLE",
  WAITING_PEOPLE = "WAITING_PEOPLE",
  HAPPENING = "HAPPENING",
  CANCEL = "CANCEL",
  DONE = "DONE",
}

export interface RaffleDetailType {
  title: string;
  bannerImg: string;
  currentTurn: number;
  nextRewardTitle: string;
  playingNumberFrom: number;
  playingNumberTo: number;
  numberOfParticipants: number;
  numberOfTurns: number;
  startTime: Date;
  raffleId: number;
  milestoneId?: number;
  answered: boolean;
  id: number;
  type: string;
  status: RaffleStatus;
  availableTicketToday: number;
  color: RaffleColorType;
  bannerUrl?: string;
}

export interface RaffleReward {
  title: string;
  detail: string;
  description: string;
  metaData: {
    imgUrl: string;
    videoUrl: string;
  };
}

export interface ListTableType {
  title: string;
  number: number[];
  winNumber?: number;
}

export interface RaffleDataBased {
  raffles: {
    nextRaffle: Raffle;
    daily: {
      raffles: Raffle[];
      total: number;
    };
    weekly: {
      raffles: Raffle[];
      total: number;
    };
    monthly: {
      raffles: Raffle[];
      total: number;
    };
    quarterly: {
      raffles: Raffle[];
      total: number;
    };
    halfYear: {
      raffles: Raffle[];
      total: number;
    };
    annually: {
      raffles: Raffle[];
      total: number;
    };
  };
}

export interface WinResultType {
  win: number;
  winner: number;
  turn: number;
  reward: string;
}
