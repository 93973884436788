export const routes = {
  landing: "/",
  login: "/login",
  referFriend: "/refer/:id",
  dashboard: "/dashboard",
  ticket: "/ticket",
  profile: "/profile",
  raffle: "/raffle",
  raffleDetail: "/raffle/:id",
  raffleResult: "/raffle-result",
  raffleResultDetail: "/raffle-result/:id",
  favoriteRaffle: "/favorite-raffle",
  exam: "/exam",
  icons: "/icons",
  widget: "/widget",
  prizeRedemption: "/prize-redemption",
};
