import { Button } from "@components/Button";
import DateTimeSelector from "@components/DateTimeSelector";
import { TextInput } from "@components/TextInput";
import { getRaffle } from "@network/Raffle";
import { RaffleParams, SearchBy } from "@network/Raffle/type";
import { RaffleDataBased } from "@type/Raffle";
import { SCREEN } from "@type/index";
import debounce from "@utils/Debounce";
import { Helmet } from "react-helmet";
import React, { useCallback, useEffect, useState } from "react";
import RaffleSwiperRender from "./Components/RaffleSwipeRender";
import * as S from "./style";
import { RAFFLE_TYPE } from "@const/dashboard";
import Loading from "@components/Loading";
import moment from "moment";

const initSearchParams: RaffleParams = {
  limit: 5,
  offset: 0,
  searchBy: SearchBy.TITLE,
  type: RAFFLE_TYPE.all,
  screen: SCREEN.RAFFLE_RESULT,
};

export const RaffleResult = ({ title }: { title: string }) => {
  const [raffleData, setRaffleData] = useState<RaffleDataBased>();
  const [raffleName, setRaffleName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState<boolean>(true);

  const onClear = () => {
    setRaffleName("");
    setFromDate("");
    setToDate("");
  };

  const getRaffleResult = useCallback(
    async (signal: AbortSignal) => {
      try {
        setLoading(true);

        initSearchParams.keyword = raffleName;
        if (fromDate || toDate) {
          const start = fromDate
            ? moment(fromDate).utc().format("YYYY-MM-DD HH:mm:ss")
            : "";
          const end = toDate
            ? moment(toDate).utc().format("YYYY-MM-DD HH:mm:ss")
            : "";

          initSearchParams.filter = `startTime,, ${start}, ${toDate}`;
        } else {
          delete initSearchParams.filter;
        }
        const response = await getRaffle(initSearchParams, signal);
        setRaffleData(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } catch (error) {
        console.error(error);

        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    },
    [raffleName, fromDate, toDate]
  );

  const debouncedFetchAll = debounce(getRaffleResult, 400);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    debouncedFetchAll(signal);

    return () => {
      controller.abort();
    };
  }, [raffleName, fromDate, toDate]);

  return (
    <>
      <Helmet>
        <title>TWO-Raffle Result</title>
      </Helmet>
      <S.Title>{title}</S.Title>
      <S.FilterWapper>
        <TextInput
          type="text"
          label="Raffle name"
          wrapperClassName="filter_input"
          placeholder="Input raffle name"
          onChange={(e) => setRaffleName(e.target.value)}
          onBlur={(e) => setRaffleName(e.target.value.trim())}
          value={raffleName}
          maxLength={100}
        />
        <DateTimeSelector
          value={fromDate}
          wrapperClassName="date_time"
          label="From"
          onChange={setFromDate as any}
          max={toDate}
        />
        <DateTimeSelector
          value={toDate}
          wrapperClassName="date_time"
          label="To"
          onChange={setToDate as any}
          min={fromDate}
        />
        <div className="content_left">
          <Button onClick={onClear}>Clear</Button>
        </div>
        <div className="raffle_swiper">
          {loading ? (
            <Loading />
          ) : (
            <RaffleSwiperRender
              raffleData={raffleData}
              screen={initSearchParams.screen}
              limit={initSearchParams.limit}
              searchBy={initSearchParams.searchBy as SearchBy}
              keyword={initSearchParams.keyword}
              filter={initSearchParams.filter}
            />
          )}
        </div>
      </S.FilterWapper>
    </>
  );
};
