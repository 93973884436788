import React from "react";
import { Row, Container, Col } from "reactstrap";
import styled from "styled-components";
import Img from "@assets/img";
import DashboardConstant from "@views/Pages/Dashboard/landing-image.constant";


export const List = styled.ul`
  list-style: none;
  padding-left: 5px;
`;

export const Item = styled.li`
  a {
    color: #fff;
    margin: 0px 5px;
    font-weight: 500;
  }
`;

export const FooterBox = styled.footer`
  background: #090042;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: space-around;
    @media (max-width: 768px) {
    padding-top: 400px;
    }
`;

export const BannerWrapper = styled.div`
   @media (max-width: 768px) {
    display: none;
    }
`

const Footer: React.FC = () => {
  return (
    <>
    <BannerWrapper>
      <img src={DashboardConstant.BannerDesktop} />
    </BannerWrapper>
    <FooterBox className="footer footer-black footer-white">
      <Container>
        <Row>
          <Col lg="12" md="12" sm="12">
            <a href=""><img src={Img.Logo} /></a>
          </Col>
          <Col lg="3" md="3" sm="12">
            <List>
              <Item><a href="">Privacy Policy</a></Item>
              <Item><a href="">Terms and Conditions</a></Item>
            </List>
          </Col>
          <Col lg="3" md="3" sm="12">
            <List>
              <Item><a href="">Advertise With Us</a></Item>
              <Item><a href="">Support</a></Item>
            </List>
          </Col>
          <Col lg="3" md="3" sm="12">
            <List>
              <Item><a href="">Advertise With Us</a></Item>
              <Item><a href="">Support</a></Item>
            </List>
          </Col>
          <Col lg="3" md="3" sm="12">
            <List className="text-center">
              <Item><a href="">Share to get free ticket</a></Item>
              <Item>
                <a href=""><img src={Img.Fb} /></a>
                <a href=""><img src={Img.X} /></a>
                <a href=""><img src={Img.Ins} /></a>
                <a href=""><img src={Img.In} /></a>
                <a href=""><img src={Img.P} /></a>
                <a href=""><img src={Img.Phone} /></a>
              </Item>
            </List>
          </Col>
        </Row>
      </Container>
    </FooterBox>
    </>
  );
};

export default Footer;
