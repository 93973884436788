import React, { useEffect } from "react";

// Reactstrap components
// import {
//   Button,
//   Card,
//   CardBody,
//   CardFooter,
//   CardTitle,
//   Form,
//   Input,
//   InputGroupAddon,
//   InputGroupText,
//   InputGroup,
//   Container,
//   Row,
//   Col,
// } from "reactstrap";

// Core components
import Navigate from "@components/Navigate";
import Header from "@components/Header";
import Footer from "@components/Footer";
// import Img from "@assets/img";

const Landing: React.FC = () => {
  useEffect(() => {
    document.title = "TWO-Wnnr";
  });
  return (
    <>
      <Navigate />
      <Header />
      <Footer />
    </>
  );
};

export default Landing;
