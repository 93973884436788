import instance from "@network/api";
import { RequestLogin } from "./type";

export async function login(payload: Omit<RequestLogin, "invitedBy">) {
  const res = await instance.post("/auth/login-by-phone", payload);

  return res.data;
}

export async function verify(payload: RequestLogin) {
  const res = await instance.post("/auth/verify-login-otp", payload);

  return res.data;
}
