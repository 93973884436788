import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  @media screen and (max-width: 768px) {
    .hidden {
      display: none;
    }
    .active {
      display: block;
    }
  }
`;

export const TableItem = styled.div<{ $bgColor?: string }>`
  background: ${(props) =>
    props.$bgColor ||
    "linear-gradient(341deg, rgba(110, 226, 245, 0.6) -28.66%, rgba(100, 84, 240, 0.6) 87.23%)"};
  /* width: 35%; */
  padding: 20px;
  border-radius: 12px;
  height: 100%;
  gap: 40px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const TableTitle = styled.div<{
  $titleColor?: string;
}>`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 12px;
  background: ${(props) =>
    props.$titleColor ||
    "linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%)"};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableHeader = styled.div<{
  $anotherColor?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.$anotherColor ?? "#f2f2f2"};
  background: linear-gradient(
    341.1deg,
    rgba(110, 226, 245, 0.3) -28.66%,
    rgba(100, 84, 240, 0.3) 87.23%
  );
  .header-item:first-child {
    width: 10%;
  }
  .header-item {
    width: 45%;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    padding: 12px;
  }
`;

export const TableBody = styled.div`
  height: 210px;
  overflow-x: hidden;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    height: 180px;
  }
`;

export const TableBodyItem = styled.div<{
  $anotherColor?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.$anotherColor ?? "#f2f2f2"};
  border-bottom: 1px solid;
  border-image: linear-gradient(
    341.1deg,
    rgba(110, 226, 245, 0.3) -28.66%,
    rgba(100, 84, 240, 0.3) 87.23%
  );
  border-image-slice: 1;
  .body-item:first-child {
    width: 10%;
  }
  .body-item {
    width: 45%;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding: 12px;
  }
`;

export const Addbtn = styled.button<{ $titleColor?: string }>`
  max-width: 80px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px 15px;
  border-radius: 8px;
  border: ${(props) =>
    props.$titleColor ? `1px solid ${props.$titleColor}` : "1px solid #0bedeb"};
  background: ${(props) =>
    props.$titleColor ||
    "linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%)"};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  button {
    padding: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    background: transparent;
    border: none;
  }
`;

export const NoSelection = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
