import React, { useRef, useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import SlotCounter from "react-slot-counter";
import { SlotCounterRef } from "react-slot-counter";
import CountdownTimer from "@components/CountdownTimer";
import { RaffleDetailType, RaffleReward } from "@type/Raffle";
import * as _ from "lodash";
import AlertPopup from "@components/AlertPopup";
import { WinResultType } from "@type/Raffle";
import { useNavigate } from "react-router-dom";

import Img from "@assets/img";
import * as S from "./style";

export interface AlertType {
  header: string;
  title: string;
  description: string;
  icon: string;
  leftButton: {
    title: string;
    onClick: () => void;
    timer: number;
  };
  highLightContent: boolean;
}

interface Props {
  raffle: RaffleDetailType | undefined;
  reward: RaffleReward | undefined;
  win?: WinResultType | null;
  done: () => void;
  isCancel: boolean;
}

const RaffleInfo = ({ raffle, reward, win, done, isCancel }: Props) => {
  const counterRef = useRef<SlotCounterRef>(null);
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertType>();
  const [cancel, setCancel] = useState<boolean>(isCancel);
  const navigate = useNavigate();

  useEffect(() => {
    counterRef.current?.startAnimation({
      duration: 1,
      dummyCharacterCount: 50,
      direction: "top-down",
    });
  }, []);

  useEffect(() => {
    setCancel(isCancel);
  }, [isCancel]);

  useEffect(() => {
    if (win && win.win) {
      counterRef.current?.startAnimation({
        duration: 30,
        dummyCharacterCount: 500,
        direction: "top-down",
      });

      setTimeout(() => {
        const alertData = {
          header: "Round " + win.turn,
          title: "Lucky Number: " + win.win,
          description: win.reward,
          icon: Img.Gift,
          leftButton: {
            title: "Close",
            onClick: () => setIsAlert(false),
            timer: 5,
          },
          highLightContent: true,
        };

        setAlert(alertData);
        setIsAlert(true);
        done();
      }, 31000);
    }
  }, [win]);

  return (
    <S.Container>
      <S.SpinContainer $bgColor={raffle?.color?.background}>
        <Marquee autoFill>
          <S.MoveText $textColor={raffle?.color?.titleTurnNext}>
            <img src={Img.rocket} alt="rocket" />
            <span>Now Playing: {reward?.title}</span>
            <img src={Img.rocket} alt="rocket" />
            <span>Next Reward: {raffle?.nextRewardTitle}</span>
          </S.MoveText>
        </Marquee>
        <S.SpinContent
          $titleColor={raffle?.color?.title}
          $anotherColor={raffle?.color?.titleAnother}
        >
          <div className="turn-playing">
            Turn {raffle?.currentTurn} is playing...
          </div>
          <p className="header-title">{reward?.title}</p>
          <p className="number-playing">
            Playing number: {raffle?.playingNumberFrom} to{" "}
            {raffle?.playingNumberTo}
          </p>
          <div className="number-participant">
            Number of participant: {raffle?.numberOfParticipants}
          </div>
          <div className="number-turn">
            Number of turn: {raffle?.numberOfTurns}
          </div>
          <S.MachineWrapper>
            <S.LuckyNumber>
              <SlotCounter
                value={
                  win?.win ? _.padStart(win.win.toString(), 8, "0") : "12345678"
                }
                autoAnimationStart={false}
                ref={counterRef}
                containerClassName="slot-counter"
                charClassName="char"
                animateUnchanged
                startValue={"00000000"}
                hasInfiniteList={true}
              />
            </S.LuckyNumber>
          </S.MachineWrapper>
          <div className="happen">
            <p className="happen-in">Start in:</p>

            <div className="happen-time">
              {raffle && (
                <CountdownTimer time={raffle?.startTime} isCancel={isCancel} />
              )}
            </div>
          </div>
        </S.SpinContent>
      </S.SpinContainer>
      <S.DetailContainer
        $bgColor={raffle?.color?.background}
        $anotherColor={raffle?.color?.titleAnother}
      >
        <div className="header">{reward?.title}</div>
        <div className="content">{reward?.detail}</div>
        <S.Hr />
        <div className="content">{reward?.description}</div>
        <div>
          {reward?.metaData.videoUrl ? (
            <S.Video src={reward?.metaData.videoUrl} />
          ) : (
            <S.ImgBox bg={reward?.metaData.imgUrl} />
          )}
        </div>
      </S.DetailContainer>
      <AlertPopup
        isOpen={isAlert}
        header={alert?.header}
        title={alert?.title}
        description={alert?.description}
        icon={alert?.icon}
        leftButton={alert?.leftButton}
        highLightContent={alert?.highLightContent}
        onDismiss={() => setIsAlert(false)}
      />
      <AlertPopup
        isOpen={cancel}
        header="Raffle Cancellation"
        title="Insufficient participants! The raffle has been cancelled"
        icon={Img.RaffleCancel}
        leftButton={{
          title: "Join Other Raffles",
          onClick: () => navigate("/dashboard"),
        }}
        onDismiss={() => setCancel(false)}
      />
    </S.Container>
  );
};

export default RaffleInfo;
