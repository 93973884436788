import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Raffle } from "@type/Raffle";
import RaffleItem from "@components/RaffleItem";
import Img from "@assets/img";

import * as S from "./style";
import { getRaffle } from "@network/Raffle";
import { SCREEN } from "@type/index";
import { SearchBy } from "@network/Raffle/type";

interface Props {
  initData?: Raffle[];
  title?: string;
  type: string;
  screen: SCREEN;
  limit: number;
  searchBy?: SearchBy;
  keyword?: string;
  filter?: string;
}

const RaffleSwiper = ({
  initData,
  title,
  type,
  screen,
  limit,
  searchBy,
  keyword,
  filter,
}: Props) => {
  const [raffleList, setRaffleList] = useState<Raffle[]>(initData ?? []);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState<number>(1);

  const fetch = async () => {
    if (isEnd === true) return;
    const data = {
      limit,
      offset: pageIndex * limit,
      type,
      screen,
      searchBy,
      keyword,
      filter,
    };
    try {
      const response = await getRaffle(data);
      setRaffleList((prev) => [...prev, ...response.data.raffles]);
      if (response.data.raffles.length < limit) {
        setIsEnd(true);
      }
      setPageIndex((prev) => prev + 1);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const updateFavoriteList = (raffleId: number) => {
    if (screen !== SCREEN.FAVORITE) return;
    setRaffleList(raffleList.filter((raffle) => raffle.raffleId !== raffleId));
  };

  useEffect(() => {
    if (initData) {
      setRaffleList(initData);
    }
  }, [initData]);

  return (
    <S.SwiperWrapper>
      {raffleList.length > 0 && (
        <>
          <h1>{title}</h1>
          <Swiper
            spaceBetween={12}
            slidesPerView={2}
            modules={[Navigation]}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            onTransitionEnd={(swiper) => {
              if (swiper.isEnd) {
                fetch();
              }
            }}
            breakpoints={{
              768: {
                slidesPerView: 3,
                spaceBetween: 12,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 12,
              },
              1440: {
                slidesPerView: 5,
                spaceBetween: 40,
              },
            }}
          >
            <div className="swiper-button-prev swiper-button-disabled">
              <img src={Img.ChevronLeft} />
            </div>
            {raffleList.map((item) => (
              <SwiperSlide key={item.id} className="h-auto">
                <RaffleItem {...item} updateFavoriteList={updateFavoriteList} />
              </SwiperSlide>
            ))}
            <div className="swiper-button-next swiper-button-disabled">
              <img src={Img.ChevronRight} />
            </div>
          </Swiper>
        </>
      )}
    </S.SwiperWrapper>
  );
};

export default RaffleSwiper;
