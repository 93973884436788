import instanceAuth from "@network/auth";
import { PaginateTicket, Tickets } from "./type";

export async function getProfile() {
  const instance = instanceAuth();
  const res = await instance.get("/users/my-profile");

  return res.data;
}

export async function updateProfile(payload: any) {
  const instance = instanceAuth();
  const res = await instance.put("/users/update", payload);

  return res.data;
}

export async function receiveTickets(payload: Tickets) {
  const instance = instanceAuth();
  const res = await instance.post("/tickets", payload);

  return res.data;
}

export async function getTicket(data: PaginateTicket) {
  const instance = instanceAuth();
  const res = await instance.get(
    `/tickets?limit=${data.limit}&offset=${data.offset}&screen=${data.screen}`
  );

  return res.data;
}

export async function updateFavorite(raffleId: number) {
  const instance = instanceAuth();
  const res = await instance.put(`/raffles/${raffleId}/favorite`);
  return res.data;
}
