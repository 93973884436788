import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

import Img from "@assets/img";

import * as S from "./style";
import { API_END_POINTS } from "@utils/EndPoint";
import { BackButton } from "@components/HeaderBar/style";

export interface SideBarProps {
  sideData?: any;
  otherSideData?: any;
}
export interface SideBar {
  id: number;
  title: string;
  link: string;
  logo: string;
  otherAction?: () => void;
}

const MAX_TITLE_WIDTH = 138;

const SideBar = ({ sideData, otherSideData }: SideBarProps) => {
  const navigate = useNavigate();

  return (
    <S.Wrapper>
      <S.Container>
        <img
          className="logo"
          src={Img.Logo}
          alt="Logo"
          width={194}
          height={"auto"}
          onClick={() => navigate(API_END_POINTS.DASHBOARD)}
        />
        {sideData?.map((element: SideBar) => {
          return (
            <React.Fragment key={element.id}>
              <SideBarItem element={element} />
            </React.Fragment>
          );
        })}
      </S.Container>
      <S.Container>
        {otherSideData?.map((element: SideBar) => {
          return (
            <React.Fragment key={element.id}>
              <SideBarItem element={element} />
            </React.Fragment>
          );
        })}
      </S.Container>
    </S.Wrapper>
  );
};

const SideBarItem = ({ element }: { element: SideBar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [itemTitleWidth, setItemTitleWidth] = useState<number>();
  const itemTitleRef = useRef<HTMLParagraphElement>(null);

  const currentLink = "/" + location.pathname.split("/")[1];
  const tooltipId = `tooltip-${element.id}`;
  const handleClickAction = () => {
    if (element.otherAction) return element.otherAction();
    navigate(element.link);
  };

  useEffect(() => {
    if (!itemTitleRef?.current?.offsetWidth) return;
    setItemTitleWidth(itemTitleRef.current.offsetWidth);
  }, []);

  return (
    <S.SideContent
      key={element.id}
      className={currentLink === element.link ? "active" : "non-active"}
      onClick={() => handleClickAction()}
    >
      <div className="box-contain">
        <img src={element.logo} alt="Logo" width={24} height={"auto"} />
      </div>
      <p
        ref={itemTitleRef}
        className="title"
        id={element.title.length > 15 ? tooltipId : ""}
      >
        {element.title}
      </p>
      {itemTitleWidth && itemTitleWidth > MAX_TITLE_WIDTH && (
        <UncontrolledTooltip delay={0} placement="right" target={tooltipId}>
          {element.title}
        </UncontrolledTooltip>
      )}
    </S.SideContent>
  );
};

export default SideBar;
