import Img from "@assets/img";
import AlertPopup from "@components/AlertPopup";
import React, { useState } from "react";
import { QuestionaryPopup } from "./components/QuestionaryPopup";

enum MODAL_TYPE {
  JOIN_QUESTIONARY = "JOIN_QUESTIONARY",
  QUESTIONARY = "QUESTIONARY",
}

export const QuestionPopup = ({
  onDismiss,
  onFinish,
  raffleId,
  milestoneId,
}: {
  onDismiss: () => void;
  onFinish: () => void;
  raffleId: number;
  milestoneId?: number;
}) => {
  const [openModalByType, setOpenModalByType] = useState<MODAL_TYPE>(
    MODAL_TYPE.JOIN_QUESTIONARY
  );

  switch (openModalByType) {
    case MODAL_TYPE.JOIN_QUESTIONARY:
      return (
        <AlertPopup
          isOpen={true}
          header="Warning"
          title="Please fill in our questionary to join the raffle!"
          icon={Img.FireLogo}
          rightButton={{
            title: "Join questionary",
            onClick: () => setOpenModalByType(MODAL_TYPE.QUESTIONARY),
          }}
          onDismiss={onDismiss}
        />
      );
    default:
    case MODAL_TYPE.QUESTIONARY:
      return (
        <QuestionaryPopup
          isOpen={openModalByType === MODAL_TYPE.QUESTIONARY}
          onDismiss={onDismiss}
          onFinish={onFinish}
          raffleId={raffleId}
          milestoneId={milestoneId}
        />
      );
  }
};
