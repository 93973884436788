import buildQuery from "@utils/BuildQuery";
import { SaveLuckyNumber, SelectedLuckyNumber } from "./type";
import instanceAuth from "@network/auth";
import { SCREEN } from "@type/index";

export async function GetAvailableTicket({ type }: { type?: string }) {
  const instance = instanceAuth();
  const res = await instance.get(
    buildQuery("/tickets", { type, screen: SCREEN.ADD_LUCKY_NUMBER })
  );
  return res.data;
}

export async function GetSelectLuckyNumber(data: SelectedLuckyNumber) {
  const instance = instanceAuth();
  const res = await instance.get(
    `/raffles/${data.raffleId}/lucky-numbers?screen=${data.screen}`
  );
  return res.data;
}

export async function SaveSelectLuckyNumber(payload: SaveLuckyNumber) {
  const instance = instanceAuth();
  const res = await instance.post(
    `/raffles/${payload.raffleId}/lucky-numbers`,
    payload.data
  );
  return res.data;
}
