import React, { useEffect, useState } from "react";

// Core components
import Navigate from "@components/Navigator";
import Sidebar from "@components/SideBar";
import HeaderBar from "@components/HeaderBar";
import ReferFriend from "@components/ReferFriend";
import BreadcrumbNav from "@components/BreadcrumbNav/BreadcrumbNav";
import TicketTable from "@components/TicketTable";
import PaginationAble from "@components/PaginateAble";
import { getTicket, getProfile } from "@network/Profile";
import TabSide from "@components/TabSide";
import {
  DataSideBar,
  DataSideDashboardBar,
  TicketScreen,
} from "@const/profile";
import { PaginateTicket } from "@network/Profile/type";
import Img from "@assets/img";

/** Style */
import * as S from "./style";
import useDataSideBar from "src/hooks/useDataSideBar";
import { Helmet } from "react-helmet";

interface StateStatus {
  Available: string;
  Used: string;
  Registered: string;
  Expired: string;
}

const dataHeader = [
  "No",
  "Ticket name",
  "Ticket type",
  "Source",
  "Issued date time",
  "Expired date time",
  "Status",
  "Used time",
  "Raffle",
  "Lucky number",
  "Reward",
  "Action",
];

const colorStatus: StateStatus = {
  Available: "#74ED2A",
  Used: "#66B2FF",
  Registered: "#F9C74F",
  Expired: "#F47C9A",
};

const ProfileTicket: React.FC<StateStatus> = () => {
  const [tableData, setTableData] = useState<any>();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [navbar, setNavbar] = useState(false);
  const { otherSideData } = useDataSideBar();
  const [walletNumber, setWalletNumber] = useState(0);
  const paginate: PaginateTicket = {
    limit: 10,
    offset: 0,
  };

  const fetchProfileData = async () => {
    try {
      const responses = await getProfile();
      setWalletNumber(responses.data.ticket.walletNumber);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const fetchData = async () => {
    paginate.offset = (currentPage - 1) * paginate.limit;

    try {
      paginate.screen = TicketScreen.MY_TICKET_LIST;
      const response = await getTicket(paginate);
      const datarows = response.data.tickets;
      const resultData = datarows.map((obj) => {
        return Object.fromEntries(
          Object.entries(obj).filter(
            ([key, value]) => key !== "createdAt" && key !== "updatedAt"
          )
        );
      });
      setTableData(resultData);
      setTotalPages(Math.ceil(response.data.total / paginate.limit));
    } catch (error) {
      console.error("Error fetching ticket data:", error);
    }
  };
  useEffect(() => {
    fetchProfileData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const changeBackGround = () => {
    if (window.scrollY > 20) {
      setNavbar(true);
    } else setNavbar(false);
  };
  window.addEventListener("scroll", changeBackGround);

  return (
    <>
      <Helmet>
        <title>TWO-Ticket</title>
      </Helmet>
      <S.Wrapper>
        <Sidebar
          sideData={DataSideDashboardBar}
          otherSideData={otherSideData}
        />
        <S.ContainerNavigate className={navbar ? "activeBg" : ""}>
          <Navigate />
          <BreadcrumbNav />
          <TabSide tabData={DataSideBar} />
        </S.ContainerNavigate>
        <S.ContainerWrapper>
          <HeaderBar backToDashboard={true} title="Back to Dashboard" />
          <S.Title>
            <h4>Ticket list</h4>
            <span>Wallet number: {walletNumber}</span>
          </S.Title>
          <TicketTable
            dataHeader={dataHeader}
            dataRows={tableData}
            nameStatus={colorStatus}
            showActionButtons={true}
            currentPage={currentPage}
            limit={paginate.limit}
          />
          {tableData?.length > 0 ? (
            <PaginationAble
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          ) : (
            <S.NoSelectionBg>
              <img src={Img.NoSelectionBg} alt="No data" width={350} />
              <p>No ticket displayed</p>
            </S.NoSelectionBg>
          )}
        </S.ContainerWrapper>
        <ReferFriend />
      </S.Wrapper>
    </>
  );
};

export default ProfileTicket;
