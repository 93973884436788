export const RAFFLE_STATUS = {
  ELIGIBLE: "Eligible",
  WAITING_PEOPLE: "Waiting for people",
  HAPPENING: "Happening",
  CANCEL: "Canceled",
  DONE: "Done",
};

export const RAFFLE_TYPE = {
  all: "ALL",
  daily: "DAILY",
  weekly: "WEEKLY",
  monthly: "MONTHLY",
  quarterly: "QUARTERLY",
  halfYear: "HALF_YEAR",
  annually: "ANNUALLY",
  special: "SPECIAL",
};
