import styled from "styled-components";
import Img from "@assets/img";
import { sm } from "src/styles/constants";

export const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  display: flex;
  background-image: url(${Img.bgDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    background-image: url(${Img.bgMobile});
  }
  .activeBg {
    background: #101355;
  }
`;

export const Top = styled.div`
  display: flex;
  gap: 40px;
  @media (max-width: 1440px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ContainerNavigate = styled.div`
  width: 100%;
  display: none;
  @media screen and (max-width: 768px) {
    position: sticky;
    top: 0;
    background-color: transparent;
    z-index: 99;
    display: block;
    padding-bottom: 5px;
  }
`;
export const TabProfile = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  .active {
    color: #00e5e8;
    border-bottom: 1px solid #00e5e8;
    font-weight: 700;
  }
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b3b3b3;
    padding: 10px 0;
    border-bottom: 1px solid #b3b3b3;
  }
`;
export const ContainerWrapper = styled.div`
  width: calc(100% - 240px);
  padding: 15px 40px;
  display: flex;
  flex-direction: column;
  .title {
    margin: 0px 0 30px;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    padding: 15px;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    .hidden {
      display: none;
    }
    .active {
      display: block;
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  margin: 40px 0 8px;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 4px;
  }

  &.raffle_result_title {
    margin-top: 16px;
  }
`;

export const SubTitle = styled.p`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #b3b3b3;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const RaffleContainer = styled.div`
  p {
    margin: 0 0 10px 0;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
  }
  @media (max-width: 768px) {
    p {
      font-size: 20px;
    }
  }
  h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 35px;
  }
`;

export const RaffleList = styled.div`
  .swiper {
    position: relative;
  }
`;

export const TableList = styled.div`
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 30px;
  gap: 20px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const DetailContainer = styled.div`
  width: 100%;
`;

export const Banner = styled.div<{ bg: string }>`
  cursor: pointer;
  width: 100%;
  height: 325px;
  background-image: url(${(p) => p.bg});
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
`;

export const ButtonStyled = styled.button`
  color: #fff;
  background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
  text-align: center;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 7px 33px;
  border-radius: 6px;
  border: none;
  width: 150px;
  margin: 20px auto;
  border-radius: 12px;
`;

export const FilterWapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 35px;
  height: 100%;
  grid-auto-rows: max-content;
  .filter_input {
    grid-column: span 6 / span 6;
  }

  .date_time {
    grid-column: span 3 / span 3;
  }

  .content_left {
    grid-column: span 12 / span 12;
    display: flex;
    justify-content: end;
  }

  .raffle_swiper {
    min-height: calc(100vh - 335px); // 335 is header layout height
    grid-column: span 12 / span 12;
  }

  @media (max-width: ${sm}) {
    column-gap: 20px;

    .filter_input {
      grid-column: span 12 / span 12;
    }

    .date_time {
      grid-column: span 12 / span 12;
    }
  }
`;
